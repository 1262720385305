import Link from 'components/GatsbyLinkWrapper/index'
import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Api, Management, Section, SimplifiedPayroll } from './style'

const Payroll = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-md-3'>
            <h2 className='fs-24 fs-lg-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-grayscale--500 fw-500 mb-3'>
              Principais serviços para sua conta PJ: pra sua empresa ir além!
            </h2>
          </div>
          <div className='col-12 col-md-6 col-lg-5 px-2'>
            <Management className='px-4 px-lg-5 pt-4 pt-lg-5 rounded-4 mb-3' role='img' aria-label='Ilustração de um notebook e tablet'>
              <h3 className='fs-20 fs-md-20 fs-lg-24 lh-24 lh-md-20 lh-lg-28 text-white fw-500'>Gestão de Cobranças completa, automatizada, recorrente e com boletos mensais gratuitos.</h3>
              <div className='d-flex justify-content-end align-items-center mt-md-5'>
                <Link
                  title='Saiba mais'
                  to='/empresas/sistema-de-cobranca-inter/'
                  className='text-right text-primary--500 fw-700'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_08',
                      element_action: 'click button',
                      element_name: 'Saiba mais - Gestão de Cobranças completa, automatizada, recorrente e com boletos mensais gratuitos.',
                      section_name: 'Principais serviços para sua conta PJ: pra sua empresa ir além!',
                      redirect_url: `${window.location.origin}/empresas/sistema-de-cobranca-inter/`,
                    })
                  }}
                >
                  Saiba mais
                </Link>
                <OrangeIcon size='MD' color='#FF7A00' icon='arrow-right' />
              </div>
            </Management>
          </div>
          <div className='col-12 col-md-6 col-lg-7'>
            <SimplifiedPayroll className='px-4 pt-4 pt-xl-5 px-xl-5 rounded-4 mb-3' role='img' aria-label='Ilustração de um notebook com a tela do internet banking em destaque'>
              <h3 className='fs-20 fs-md-20 fs-lg-24 lh-24 lh-md-20 lh-lg-28 text-grayscale--500 fw-500'>Folha de Pagamento simplificada, em um ambiente fácil, rápido, controlado e sem custos.</h3>
              <div className='d-flex justify-content-end align-items-center'>
                <Link
                  title='Saiba mais'
                  to='/empresas/folha-de-pagamento/'
                  className='text-right text-primary--500 fw-700'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_08',
                      element_action: 'click button',
                      element_name: 'Saiba mais - Folha de Pagamento simplificada, em um ambiente fácil, rápido, controlado e sem custos',
                      section_name: 'Principais serviços para sua conta PJ: pra sua empresa ir além!',
                      redirect_url: `${window.location.origin}/empresas/folha-de-pagamento/`,
                    })
                  }}
                >
                  Saiba mais
                </Link>
                <OrangeIcon size='MD' color='#FF7A00' icon='arrow-right' />
              </div>
            </SimplifiedPayroll>
            <Api className='px-4 pt-4 px-xl-5 pt-xl-5 rounded-4'>
              <h3 className='fs-20 fs-md-20 fs-lg-24 lh-24 lh-md-20 lh-lg-28 text-grayscale--500 fw-500'>API Inter Empresas para integrar nossa conta digital com os sistemas que seu negócio utiliza.</h3>
              <div className='d-flex justify-content-end align-items-center mb-4 mb-lg-5 mb-xl-3'>
                <Link
                  title='Saiba mais'
                  to='/empresas/integracoes-para-empresas/'
                  className='text-right text-primary--500 fw-700'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_08',
                      element_action: 'click button',
                      element_name: 'Saiba mais - API Inter Empresas para integrar nossa conta digital com os sistemas que seu negócio utiliza',
                      section_name: 'Principais serviços para sua conta PJ: pra sua empresa ir além!',
                      redirect_url: `${window.location.origin}/empresas/integracoes-para-empresas/`,
                    })
                  }}
                >
                  Saiba mais
                </Link>
                <OrangeIcon size='MD' color='#FF7A00' icon='arrow-right' />
              </div>
              <div className='d-flex justify-content-center mt-xl-4'>
                <ImageWebp
                  arrayNumbers={[ 276, 250, 338, 384 ]}
                  arrayNumbersHeight={[ 138, 125, 169, 192 ]}
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/negocio-dobra8-3/image.webp'
                  altDescription='Cliente Inter Empresas sorrindo'
                />
              </div>
            </Api>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Payroll
